/* Layout */

.max-container-900 {
  max-width: 900px !important;
  margin: auto;
}

.max-container-750 {
  max-width: 750px !important;
  margin: auto;
}

.max-container-750-flex {
  max-width: 750px !important;
  margin: auto;
}

.top-adjust {
  margin-top: 5rem;
  margin-bottom: 2rem;
}

.top-adjust-padding {
  padding-top: 5rem;
}

/* .top-adjust-padding-10rem {
    padding-top: 10rem;
} */

/* Layout */

/* Table */
.table-head-line {
  border-bottom: 2px solid #ddd;
}

.table-head-line th {
  min-width: 80px;
}

/* Text */
.my-title {
  font-size: 32px;
  line-height: 40px;
  font-weight: bold;
  color: #202123;
}

.my-sub-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: #202123;
}

.text-both-end {
  text-align: justify;
}

/* @supports (hyphens: auto) {
    .text-both-end {
        hyphens: auto;
    }
} */

.no-underline {
  text-decoration: none;
}

/* Text */

/* border */

/* Size */
.width-30 {
  width: 30px;
}

.width-100 {
  width: 100px;
}

.width-250 {
  width: 250px;
}

.width-300-important {
  width: 300px !important;
  height: 40px;
}

.width-300 {
  width: 300px;
}

.width-350 {
  width: 350px;
  height: 50px;
}

.width-350-no-height {
  width: 350px;
}

.width-500 {
  width: 500px;
}

.width-650 {
  width: 650px;
}

.width-750 {
  width: 750px;
}

.font-12px {
  font-size: 12px !important;
}

.font-14px {
  font-size: 14px !important;
}

/* Size */

/* Button */
.btn-125 {
  width: 125px;
  height: 45px;
  font-size: 16px;
}

.btn-200 {
  width: 200px;
  height: 50px;
  font-size: 16px;
}

.btn-250 {
  width: 250px;
  height: 50px;
  font-size: 21px;
}

.responsive-btn-250 {
  width: 250px;
  height: 50px;
  font-size: 21px;
}

.btn-350 {
  width: 350px;
  height: 50px;
  font-size: 21px;
}

.green-btn {
  color: #fff;
  background-color: #10a37f;
  /* border: none; */
  border: 1px solid rgba(0, 0, 0, 0.176);
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.green-btn:hover {
  filter: brightness(1.1);
}

.blue-green-btn {
  color: #fff;
  background-color: #008080;
  /* border: none; */
  border: 1px solid rgba(0, 0, 0, 0.176);
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.blue-green-btn:hover {
  filter: brightness(1.1);
}

.basic-btn {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.176);
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.basic-btn:hover {
  filter: brightness(1.1);
}

/* Button */

/* Input */
.rounded-input-32 {
  border-radius: 6px;
  border: 1px solid #a9a9a9;
  height: 32px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  padding-left: 1rem;
}

.rounded-input-50 {
  border-radius: 6px;
  border: 1px solid #a9a9a9;
  height: 50px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  padding-left: 1rem;
}

.hr-text {
  align-items: center;
  display: flex;
  color: #888;
  font-size: 1em;
  width: 100%;
  max-width: 350px;
  /* Add this line */
}

.hr-text::before,
.hr-text::after {
  content: '';
  border-top: 1px solid;
  margin: 0 20px 0 0;
  flex: 1 1 50%;
}

.hr-text::before {
  margin: 0 20px 0 0;
}

.hr-text::after {
  margin: 0 0 0 20px;
}

/* shadow variation */

.card-shadow {
  box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1);
}

/* list */
.listItem {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
}

.listItem::before {
  content: '\2713';
  position: absolute;
  left: 0;
  top: 4px;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  background-color: blue;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 20px;
}

/* color */
.bg-pale-yellow {
  background-color: #fff3cd;
  /* same color as bootstrap <mark></mark> */
  /* pale yellow */
}

.bg-pale-blue {
  background-color: #dbf0ff;
  /* pale blue */
}

.bg-pale-gray {
  background-color: #fbfbfb !important;
}

/* same bg color in polymathnyc.com */
.pale-background {
  background-color: #fafafa;
}

.text-gold {
  color: #ffd700;
}

/* header dropdown menu triangle */
.dropdown-toggle::after {
  /* color: rgb(64, 64, 64); */
  color: lightgray;
}

/* .arrow-lightgray::after {
    border-top-color: lightgray !important;
} */

.guides-dropdown-toggle:hover {
  color: white !important;
}

/* .dropdown-toggle::after {
  display: none !important; 
} */

.arrow-off::after {
  display: none !important;
}

/* lignt mode */
.bg-white {
  background-color: white;
}

/* stylish mode */
.bg-darkblue {
  background-color: #14213d;
}

.text-lightgray {
  fill: lightgray;
}

/* color */

/* image */
.top-image {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 30vh;
  opacity: 1;
  object-position: center 50%;
}

/* border */
.title-border-radius {
  border-radius: 0.8rem;
}

.border-bottom-lightgray {
  border-bottom: solid 1px #ddd;
}

.border-bottom-gray {
  border-bottom: solid 2px #bbb;
}

.border-top-gray {
  border-top: solid 2px #bbb;
}

/* Dropdown.Item */
.non-hoverable:hover {
  background-color: inherit !important;
  color: inherit !important;
}

/* tooltip */
/* #essay-form-wrapper .textarea-container .textarea-tooltip {
    background-color: #f39c12;
    color: white;
    border-radius: 8px;
}

#essay-form-wrapper .textarea-container .cog-tooltip {
    background-color: #f39c12;
    color: white;
    border-radius: 8px;
} */

/* animation */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.rotate {
  transition: all 0.1173s linear;
}

.rotate:hover {
  transform: rotate(15deg);
}

.rotate:focus {
  outline: none;
}

/* for Safari */
/* text-align:center doesn't work in Safari */
.safari-select {
  text-indent: 50%;
}

/* mobile */

@media screen and (max-width: 768px) {
  .top-adjust {
    margin-top: 7rem;
  }

  .top-adjust-padding {
    padding-top: 10rem;
  }

  .width-500 {
    width: 290px;
  }

  .width-650 {
    width: 360px;
  }

  .width-750 {
    width: 360px;
  }

  .max-container-750 {
    max-width: 360px !important;
    margin: auto;
  }

  .responsive-btn-250 {
    width: 200px;
    font-size: 18px;
  }
}
