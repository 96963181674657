@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@500display=swap");

html {
    scroll-behavior: smooth;
}

/* polymath introduction style */
.intro-center {
  display: flex;
  justify-content: center;
}

.intro-sp {
font-size: 14px;
width: 360px;
margin-top: 100px;
}

.intro-pc {
font-size: 16px;
width: 950px;
}

/* polymath member's picture style */
.headshot {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  filter: grayscale(100%);
}

/* subtite style */
.subTitle {
  font-size: 44px;
  font-family: "Cormorant Garamond", serif;
  font-weight: 500;
  text-decoration: none;
  color: black;
  text-align: center;
}

/* background color applied for every other section */
.pale-background {
    background-color: #FAFAFA;
}

/* text style */
.book-link {
  display: inline;
  color: black;
}

.small-letter {
    font-size: 14px;
}

.text-justify {
  text-align: justify;
}

p {
  text-align: justify;
  max-width: 950px;
}

.medium-letter {
    font-size: 20px;
}

.font-bold {
    font-weight: bold;
}

/* polymath value style  */
.merits-title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.merits-title-sp {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.polymath-value {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.value-contents-width-sp {
  max-width: 360px;
}

.value-font-size-sp {
  font-size: 14px;
}


/* achievements style */
.achievements {
    margin-top: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.achievements-list {
  font-size: 16px;
}

.achievements-list-sp {
  font-size: 14px;
}

.ul-align-center {
  display: flex;
  align-items: center;
  flex-direction: column;
}

li {
  text-align: left;
  padding-bottom: 5px;
}

.li-width-sp {
  max-width: 320px;
  padding-right: 20px;
}

/* university logo style */
.univ-logo-wrapper {
  text-align: center;
}

.univ-logo-wrapper-sp {
  text-align: center;
  max-width: 360px;
}

.univ-logo {
  /* height: 50px; */
  width: 75px;
  margin: 0 15px 30px 15px;
}


/* social section style  */
.social-smartphone {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.social-pc {
    display: flex;
    justify-content: center;
}

.social-width-pc {
  width: 950px;
}

.social-wrapper-pc {
  display: flex;
  justify-content: space-around 
}

/* posiiton adjustments --- as a header is fixed, positive padding and negative margin were added to keep the position when an internal link is clicked */

.header-adjustment {
  padding-top: 70px;
  margin-top: -70px;
}

.link-style-deactivate {
  color: black;
  text-decoration: none;
}

.link-style-deactivate:hover {
  color: black;
  text-decoration: none;
}

.link-white-style-deactivate {
  color: white;
  text-decoration: none;
}

.link-white-style-deactivate:hover {
  color: white;
  text-decoration: none;
}
