/* for smartphone */

header {
  width: 100%;
  background-color: white;
  position: fixed;
  z-index: 1;
}

.image-center {
  display: flex;
  justify-content: center;
}

.nav-sp {
  width: 360px;
}

.polymath-logo-sp {
  width: 250px;
}


/* for pc  */

.polymath-logo {
  width: 250px;
}

.navbar-pc {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-font-size {
  font-size: 14px;
}

.navbar-color {
  color: gray;
}

.navbar-pc-wrapper {
  display: flex;
  justify-content: center;
}

.navbar-width {
  width: 950px;
}

.nav-link-decoration {
  text-decoration: none;
}


















