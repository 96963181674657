@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@400;500;700&display=swap');

.form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  background-color: blue;
  border: none;
  height: 30px;
  color: white;
  border-radius: 5px;
}

textarea {
  height: 150px;
  background-color: blue;
  color: white;
  border: none;
  border-radius: 5px;
}

select {
  background-color: blue;
  color: white;
  height: 30px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.option-font {
  font-size: 16px;
}

#message {
  height: 150px;
}

input::placeholder {
  color: white;
  padding-left: 5px;
  font-size: 16px;
}

textarea::placeholder {
  color: white;
  padding-left: 5px;
  font-size: 16px;
}

select::placeholder {
  color: white;
  padding-left: 15px;
  font-size: 16px;
}

optgroup {
  font-size: 60px;
}

option {
  font-size: 30px;
}

.button {
  color: blue;
  background-color: white;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  width: 150px;
  height: 43.5px;
  /* width: 110px;
  height: 36px; */
  text-align: center;
}

.smartphone-contact {
  width: 320px;
}

.pc {
  width: 400px;
}

.line-wrapper {
  display: flex;
  justify-content: center;
}

.line-logo {
  width: 150px ;
  height:43.5px ;
  border-radius: 10px;
}

.contact-wrapper-pc {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.line-relative {
  position: relative;
}

.line-absolute {
  position: absolute;
  top: 10px;
  right: 14px;
  background-color: #06c755;
  font-family: 'M PLUS 1p', sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: white;
}